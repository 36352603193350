<header class="breakout" [@slideIn]="slideInState()">
  <div class="header-logo-container">
    <vk-header-logo />
    @if (breakpointState.isDesktop()) {
      <vk-header-lang-selector />
    }
  </div>

  <div class="header-menu-container">
    <vk-header-visit-plan />
    @if (!breakpointState.isDesktop()) {
      <div class="header-menu-separator"></div>
    }

    @if (breakpointState.isDesktop()) {
      <vk-header-profile-menu />
      <vk-header-search />
      <vk-header-megamenu />
    } @else {
      <vk-header-profile-menu />
      <div class="header-menu-separator"></div>
      <vk-header-lang-selector />
      <div class="header-menu-separator"></div>
      <vk-header-megamenu />
    }
  </div>
</header>
