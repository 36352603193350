import { ContentConfig } from '@trendency/simple';
import {
  BrandsLogosResponseType,
  DefaultResponseType,
  EmbedFacebookPostResponseType,
  EmbedFacebookVideoResponseType,
  EmbedJotformResponseType,
  EmbedTikTokResponseType,
  EmbedYoutubeResponseType,
  EmbedYoutubeShortResponseType,
  GalleryResponseType,
  HomeBrandResponseType,
  HomeFreshNewsResponseType,
  HomeHeroResponseType,
  HomeProgramCarouselResponseType,
  HomeVisitPlanningResponseType,
  LandingButtonResponseType,
  LandingCtaResponseType,
  LandingCtaWithMediaResponseType,
  LandingHeroResponseType,
  LandingJotFormResponseType,
  LandingKpiResponseType,
  LandingMapResponseType,
  LandingSightBrandResponseType,
  LandingTextResponseType,
  LandingTitleResponseType,
  LandingZigzagResponseType,
  MenusCardsResponseType,
  NewsletterResponseType,
  RecommendedNewsCarouselResponseType,
  RecommendedProgramsCarouselResponseType,
  RecommendedShopsCarouselResponseType,
  RecommendedSightsCarouselResponseType,
  SearchResultAllResponseType,
  SearchResultFaqResponseType,
  SearchResultNewsResponseType,
  SearchResultProgramsResponseType,
  SearchResultSightsResponseType,
} from '@widgets/components';

export const simpleConfig: ContentConfig = {
  // Home widgets
  [HomeBrandResponseType]: () =>
    import('@widgets/components/home/home-brands/configs/home-brands.widget.config').then((c) => c.initSimpleConfig()),
  [HomeFreshNewsResponseType]: () =>
    import('@widgets/components/home/home-fresh-news/configs/home-fresh-news.widget.config').then((c) => c.initSimpleConfig()),
  [HomeVisitPlanningResponseType]: () =>
    import('@widgets/components/home/home-visit-planning/configs/home-visit-planning.widget.config').then((c) => c.initSimpleConfig()),
  [HomeProgramCarouselResponseType]: () =>
    import('@widgets/components/home/home-program-carousel/configs/home-program-carousel.widget.config').then((c) => c.initSimpleConfig()),
  [HomeHeroResponseType]: () =>
    import('@widgets/components/home/home-hero/configs/home-hero.widget.config').then((c) => c.initSimpleConfig()),

  // Landing widgets
  [LandingButtonResponseType]: () =>
    import('@widgets/components/landing/landing-button/configs/landing-button.widget.config').then((c) => c.initSimpleConfig()),
  [LandingCtaResponseType]: () =>
    import('@widgets/components/landing/landing-cta/configs/landing-cta.widget.config').then((c) => c.initSimpleConfig()),
  [LandingCtaWithMediaResponseType]: () =>
    import('@widgets/components/landing/landing-cta-with-media/configs/landing-cta-with-media.widget.config').then((c) =>
      c.initSimpleConfig()
    ),
  [LandingHeroResponseType]: () =>
    import('@widgets/components/landing/landing-hero/configs/landing-hero.widget.config').then((c) => c.initSimpleConfig()),
  [LandingJotFormResponseType]: () =>
    import('@widgets/components/landing/landing-jot-form/configs/landing-jot-form.widget.config').then((c) => c.initSimpleConfig()),
  [LandingKpiResponseType]: () =>
    import('@widgets/components/landing/landing-kpi/configs/landing-kpi.widget.config').then((c) => c.initSimpleConfig()),
  [LandingMapResponseType]: () =>
    import('@widgets/components/landing/landing-map/configs/landing-map.widget.config').then((c) => c.initSimpleConfig()),
  [LandingSightBrandResponseType]: () =>
    import('@widgets/components/landing/landing-sight-brand/configs/landing-sight-brand.widget.config').then((c) => c.initSimpleConfig()),
  [LandingTextResponseType]: () =>
    import('@widgets/components/landing/landing-text/configs/landing-text.widget.config').then((c) => c.initSimpleConfig()),
  [LandingTitleResponseType]: () =>
    import('@widgets/components/landing/landing-title/configs/landing-title.widget.config').then((c) => c.initSimpleConfig()),
  [LandingZigzagResponseType]: () =>
    import('@widgets/components/landing/landing-zigzag/configs/landing-zigzag.widget.config').then((c) => c.initSimpleConfig()),

  // Shared widgets
  [BrandsLogosResponseType]: () =>
    import('@widgets/components/shared/brands-logos/configs/brands-logos.widget.config').then((c) => c.initSimpleConfig()),
  [MenusCardsResponseType]: () =>
    import('@widgets/components/shared/menus-cards/configs/menus-cards.widget.config').then((c) => c.initSimpleConfig()),
  [NewsletterResponseType]: () =>
    import('@widgets/components/shared/newsletter/configs/newsletter.widget.config').then((c) => c.initSimpleConfig()),
  [DefaultResponseType]: () => import('@widgets/components/shared/default/configs/default.widget.config').then((c) => c.initSimpleConfig()),
  [GalleryResponseType]: () => import('@widgets/components/shared/gallery/configs/gallery.widget.config').then((c) => c.initSimpleConfig()),
  [RecommendedNewsCarouselResponseType]: () =>
    import('@widgets/components/shared/carousels/recommended-news-carousel/configs/recommended-news-carousel.widget.config').then((c) =>
      c.initSimpleConfig()
    ),
  [RecommendedShopsCarouselResponseType]: () =>
    import('@widgets/components/shared/carousels/recommended-shops-carousel/configs/recommended-shops-carousel.widget.config').then((c) =>
      c.initSimpleConfig()
    ),
  [RecommendedProgramsCarouselResponseType]: () =>
    import('@widgets/components/shared/carousels/recommended-programs-carousel/configs/recommended-programs-carousel.widget.config').then(
      (c) => c.initSimpleConfig()
    ),
  [RecommendedSightsCarouselResponseType]: () =>
    import('@widgets/components/shared/carousels/recommended-sights-carousel/configs/recommended-sights-carousel.widget.config').then((c) =>
      c.initSimpleConfig()
    ),

  // Embed widgets
  [EmbedFacebookPostResponseType]: () =>
    import('@widgets/components/shared/embeds/embed-facebook-post/configs/embed-facebook-post.widget.config').then((c) =>
      c.initSimpleConfig()
    ),
  [EmbedFacebookVideoResponseType]: () =>
    import('@widgets/components/shared/embeds/embed-facebook-video/configs/embed-facebook-video.widget.config').then((c) =>
      c.initSimpleConfig()
    ),
  [EmbedJotformResponseType]: () =>
    import('@widgets/components/shared/embeds/embed-jotform/configs/embed-jotform.widget.config').then((c) => c.initSimpleConfig()),
  [EmbedTikTokResponseType]: () =>
    import('@widgets/components/shared/embeds/embed-tiktok/configs/embed-tiktok.widget.config').then((c) => c.initSimpleConfig()),
  [EmbedYoutubeResponseType]: () =>
    import('@widgets/components/shared/embeds/embed-youtube/configs/embed-youtube.widget.config').then((c) => c.initSimpleConfig()),
  [EmbedYoutubeShortResponseType]: () =>
    import('@widgets/components/shared/embeds/embed-youtube-short/configs/embed-youtube-short.widget.config').then((c) =>
      c.initSimpleConfig()
    ),

  // Search widgets
  [SearchResultAllResponseType]: () =>
    import('@widgets/components/search/search-result-all/configs/search-result-all.widget.config').then((c) => c.initSimpleConfig()),
  [SearchResultProgramsResponseType]: () =>
    import('@widgets/components/search/search-result-programs/configs/search-result-programs.widget.config').then((c) =>
      c.initSimpleConfig()
    ),
  [SearchResultNewsResponseType]: () =>
    import('@widgets/components/search/search-result-news/configs/search-result-news.widget.config').then((c) => c.initSimpleConfig()),
  [SearchResultFaqResponseType]: () =>
    import('@widgets/components/search/search-result-faq/configs/search-result-faq.widget.config').then((c) => c.initSimpleConfig()),
  [SearchResultSightsResponseType]: () =>
    import('@widgets/components/search/search-result-sights/configs/search-result-sights.widget.config').then((c) => c.initSimpleConfig()),
};
