@if (breakpointState.isDesktop()) {
  <button
    class="header-menu-toggle"
    #profileMenuButton
    [cdkMenuTriggerFor]="profileMenuCdkTemplate"
    [vkBtn]="'secondary'"
    (click)="onProfileMenuClick()"
  >
    <vk-icon name="icon-user" />
    {{
      authState.isLoggedIn()
        ? ('HEADER.LINKS.PROFILE_MENU.HELLO' | transloco) + ' ' + this.authState.user.firstName()
        : ('HEADER.LINKS.PROFILE' | transloco)
    }}
    <vk-icon [name]="isProfileMenuActive() ? 'icon-chevron-up' : 'icon-chevron-down'" />
  </button>
} @else {
  <button class="header-menu-button" #profileMenuButton (click)="onSmallProfileMenuClick()">
    <vk-icon [name]="'icon-user' + (authState.isLoggedIn() ? '-check' : '')" />
  </button>
}

<nav class="header-user-menu-container small" #profileMenu [ngClass]="{ isOpen: isSmallProfileMenuActive() }">
  @if (authState.isLoggedIn()) {
    <div class="header-user-menu-header">
      <p class="header-user-menu-title is-alternative">
        {{ ('HEADER.LINKS.PROFILE_MENU.HELLO' | transloco) + ' ' + this.authState.user.firstName() }}
      </p>
      <button class="header-user-menu-exit" (click)="onSmallProfileMenuClick()">
        {{ 'HEADER.LINKS.PROFILE_MENU.CLOSE' | transloco }} <vk-icon name="icon-close"></vk-icon>
      </button>
    </div>

    <ul class="header-menu align-left-items">
      @for (headerMenu of profileMenuData; track $index) {
        <li class="header-menu-item">
          <a
            class="header-menu-item-button align-left-item"
            [routerLink]="headerMenu.slug | localize"
            (click)="onSmallProfileMenuClick()"
            vkBtn
            >{{ headerMenu.title | transloco }}</a
          >
        </li>
      }
    </ul>

    <div class="header-menu-item-last">
      <ul class="header-menu align-left-items">
        <li class="header-menu-item">
          <a class="header-menu-item-button align-left-item" (click)="onLogout()" vkBtn
            ><vk-icon name="icon-log-out" /> {{ 'HEADER.LINKS.PROFILE_MENU.LOGOUT' | transloco }}</a
          >
        </li>
      </ul>
    </div>
  } @else {
    <div class="header-user-menu-header">
      <p class="header-user-menu-title is-alternative">{{ 'HEADER.LINKS.PROFILE' | transloco }}</p>
      <button class="header-user-menu-exit" (click)="onSmallProfileMenuClick()">
        {{ 'HEADER.LINKS.PROFILE_MENU.CLOSE' | transloco }} <vk-icon name="icon-close"></vk-icon>
      </button>
    </div>

    <ul class="header-menu">
      <li class="header-menu-item">
        <a
          class="header-menu-item-button"
          [routerLink]="routesFullPaths.AUTH_LOGIN | localize"
          [scheme]="'dark'"
          (click)="onSmallProfileMenuClick()"
          vkBtn
          >{{ 'HEADER.LINKS.PROFILE_MENU.LOGIN' | transloco }}</a
        >
      </li>
      <li class="header-menu-item">
        <a
          class="header-menu-item-button"
          [routerLink]="routesFullPaths.AUTH_REGISTRATION | localize"
          [scheme]="'dark'"
          [vkBtn]="'secondary'"
          (click)="onSmallProfileMenuClick()"
          >{{ 'HEADER.LINKS.PROFILE_MENU.REGISTER' | transloco }}</a
        >
      </li>
    </ul>
  }
</nav>

<ng-template #profileMenuCdkTemplate>
  <nav class="header-user-menu-container" #profileMenu>
    <ul class="header-menu align-left-items" cdkMenu>
      @if (authState.isLoggedIn()) {
        @for (headerMenu of profileMenuData; track $index) {
          <li class="header-menu-item" cdkMenuItem>
            <a class="header-menu-item-button align-left-item" [routerLink]="headerMenu.slug | localize" vkBtn>{{
              headerMenu.title | transloco
            }}</a>
          </li>
        }
        <li class="header-menu-item" cdkMenuItem>
          <a class="header-menu-item-button align-left-item" (click)="onLogout()" vkBtn>
            {{ 'HEADER.LINKS.PROFILE_MENU.LOGOUT' | transloco }}</a
          >
        </li>
      } @else {
        <li class="header-menu-item" cdkMenuItem>
          <a
            class="header-menu-item-button align-left-item"
            [routerLink]="routesFullPaths.AUTH_LOGIN | localize"
            [scheme]="'white'"
            vkBtn
            >{{ 'HEADER.LINKS.PROFILE_MENU.LOGIN' | transloco }}</a
          >
        </li>
        <li class="header-menu-item" cdkMenuItem>
          <a
            class="header-menu-item-button align-left-item"
            [routerLink]="routesFullPaths.AUTH_REGISTRATION | localize"
            [scheme]="'white'"
            [vkBtn]="'primary'"
            >{{ 'HEADER.LINKS.PROFILE_MENU.REGISTER' | transloco }}</a
          >
        </li>
      }
    </ul>
  </nav>
</ng-template>
