import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { BreakpointState } from '@core/states/breakpoint/breakpoint.state';
import { TranslocoPipe } from '@jsverse/transloco';
import { IconComponent } from '@shared/components';
import { BtnDirective } from '@shared/directives';

@Component({
  selector: 'vk-header-visit-plan',
  templateUrl: './header-visit-plan.component.html',
  styleUrls: ['./header-visit-plan.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslocoPipe, BtnDirective, IconComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderVisitPlanComponent {
  readonly breakpointState = inject(BreakpointState);
}
