import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Brand, BrandsState } from '@core/states/brands/brands.state';
import { patchState, signalState } from '@ngrx/signals';
import { DeepSignal } from '@ngrx/signals/src/deep-signal';
import { ApiResponse, ImageResponse } from '@shared/types';
import { ContentSeoService } from '@trendency/simple/page';
import { ContentPageMeta } from '@trendency/simple/page/types/content-page.types';
import { map, Observable, tap } from 'rxjs';

type InitConfigResponse = readonly {
  readonly key: string;
  readonly value: string;
  readonly media?: ImageResponse;
}[];

type BrandsResponse = {
  readonly id: string;
  readonly title: string;
  readonly color: string;
  readonly media: {
    readonly url: string;
  } | null;
  readonly icon: {
    readonly url: string;
  } | null;
  readonly transparentIcon: {
    readonly url: string;
  } | null;
  readonly textTheme: 'light' | 'dark';
  readonly buttonTheme: 'light' | 'dark';
};

type InitResponseType = {
  readonly configs: InitConfigResponse;
  readonly meta: ContentPageMeta | null;
  readonly brands: readonly BrandsResponse[];
};

type InitConfig = {
  readonly [key: string]: string;
};

@Injectable({
  providedIn: 'root',
})
export class InitService {
  readonly #configState = signalState<InitConfig>({});
  readonly #http = inject(HttpClient);
  readonly #seo = inject(ContentSeoService);
  readonly #brandsState = inject(BrandsState);

  configState: DeepSignal<InitConfig> = this.#configState;

  init$(): Observable<InitConfig> {
    return this.#http.get<ApiResponse<InitResponseType>>('').pipe(
      tap((res) => {
        this.#seo.setMeta(res.data.meta, res.data.meta?.title);
        this.#brandsState.brands = res.data.brands.map<Brand>((brand) => ({
          id: brand.id,
          title: brand.title,
          color: brand.color,
          imageUrl: brand.media?.url || '',
          iconUrl: brand.icon?.url || '',
          textTheme: brand.textTheme,
          buttonTheme: brand.buttonTheme,
          transparentIconUrl: brand.transparentIcon?.url || '',
        }));
      }),
      map((res) => {
        return res.data.configs.reduce<InitConfig>((acc, curr) => {
          return {
            ...acc,
            [curr.key]: curr.value,
          };
        }, {});
      }),
      tap((configs) => patchState(this.#configState, configs))
    );
  }
}
