import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, HostBinding, inject } from '@angular/core';
import { BreakpointState } from '@core/states/breakpoint/breakpoint.state';
import { HomeState } from '@features/home/states/home.state';
import { CONTENT_GRID } from '@shared/constants';

import { HeaderLangSelectorComponent } from './components/header-lang-selector/header-lang-selector.component';
import { HeaderLogoComponent } from './components/header-logo/header-logo.component';
import { HeaderMegamenuComponent } from './components/header-megamenu/header-megamenu.component';
import { HeaderProfileMenuComponent } from './components/header-profile-menu/header-profile-menu.component';
import { HeaderSearchComponent } from './components/header-search/header-search.component';
import { HeaderVisitPlanComponent } from './components/header-visit-plan/header-visit-plan.component';

@Component({
  selector: 'vk-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    HeaderLangSelectorComponent,
    HeaderProfileMenuComponent,
    HeaderMegamenuComponent,
    HeaderVisitPlanComponent,
    HeaderSearchComponent,
    HeaderLogoComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideIn', [
      state(
        'hidden',
        style({
          transform: 'translateY(-100%)',
          opacity: 0,
        })
      ),
      state(
        'visible',
        style({
          transform: 'none',
          opacity: 1,
        })
      ),
      transition('hidden => visible', [animate('800ms ease-in')]),
    ]),
  ],
})
export class HeaderComponent {
  @HostBinding(CONTENT_GRID) contentGrid = true;

  readonly #homeState = inject(HomeState);
  readonly breakpointState = inject(BreakpointState);
  readonly slideInState = computed(() => (this.#homeState.slideIn().homeSlideInHidden ? 'hidden' : 'visible'));
}
