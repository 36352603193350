import { routesEnum } from '@core/routes/app.routes-config';
import { LocalizeConfig } from '@trendency/ng-translate';

export const localizeRouteConfig: LocalizeConfig = {
  routes: () => import('@app/app.routes').then((module) => module.appRoutes),
  hideDefaultLanguagePrefix: true,
  translateLoaderOptions: {
    skipInterceptors: true,
  },
  routerTranslations: {
    hu: {
      [routesEnum.HOME]: '',
      [routesEnum.NOT_FOUND]: 'nem-talalhato',
      [routesEnum.WORK_IN_PROGRESS]: 'munka-folyamatban',
      [routesEnum.AUTH]: 'azonositas',
      [routesEnum.AUTH_LOGIN]: 'belepes',
      [routesEnum.AUTH_REGISTRATION]: 'regisztracio',
      [routesEnum.AUTH_FORGOTTEN_PASSWORD]: 'elfelejtett-jelszo',
      [routesEnum.AUTH_RESET_PASSWORD]: 'jelszo-visszaallitas',
      [routesEnum.AUTH_ACTIVATION]: 'aktivalas',
      [routesEnum.PROFILE]: 'profil',
      [routesEnum.PROFILE_DATA]: 'profil-adatok',
      [routesEnum.PROFILE_PASSWORD_CHANGE]: 'jelszo-valtoztatas',
      [routesEnum.PROFILE_DATA_CHANGE]: 'adatok-szerkesztes',
      [routesEnum.PROFILE_BOUGHT_TICKETS]: 'megvasarolt-jegyek',
      [routesEnum.NEWS]: 'hirek',
      [routesEnum.NEWS_ARTICLE]: 'cikk',
      [routesEnum.NEWS_TAG]: 'cimke',
      [routesEnum.PROGRAMS]: 'programok',
      [routesEnum.SIGHTS]: 'latnivalok',
      [routesEnum.CONTACT_US]: 'kapcsolat',
      [routesEnum.SEARCH]: 'kereses',
      [routesEnum.LANDING_THE_BUDAVARI_PALACE_DISTRICT]: 'a-budavari-palotanegyed',
      [routesEnum.AREA_UTILIZATION]: 'terulet-hasznositas',
      [routesEnum.AREA_UTILIZATION_VENUE_RENTAL]: 'helyszinberles',
    },
    en: {
      [routesEnum.HOME]: '',
      [routesEnum.NOT_FOUND]: 'not-found',
      [routesEnum.WORK_IN_PROGRESS]: 'work-in-progress',
      [routesEnum.AUTH]: 'auth',
      [routesEnum.AUTH_LOGIN]: 'login',
      [routesEnum.AUTH_REGISTRATION]: 'registration',
      [routesEnum.AUTH_FORGOTTEN_PASSWORD]: 'forgotten-password',
      [routesEnum.AUTH_RESET_PASSWORD]: 'reset-password',
      [routesEnum.AUTH_ACTIVATION]: 'activation',
      [routesEnum.PROFILE]: 'profile',
      [routesEnum.PROFILE_DATA]: 'profile-data',
      [routesEnum.PROFILE_PASSWORD_CHANGE]: 'profile-password-change',
      [routesEnum.PROFILE_DATA_CHANGE]: 'profile-data-change',
      [routesEnum.PROFILE_BOUGHT_TICKETS]: 'profile-bought-tickets',
      [routesEnum.NEWS]: 'news',
      [routesEnum.NEWS_ARTICLE]: 'article',
      [routesEnum.NEWS_TAG]: 'tag',
      [routesEnum.PROGRAMS]: 'programs',
      [routesEnum.SIGHTS]: 'sights',
      [routesEnum.CONTACT_US]: 'contact-us',
      [routesEnum.SEARCH]: 'search',
      [routesEnum.LANDING_THE_BUDAVARI_PALACE_DISTRICT]: 'the-budavari-palace-district',
      [routesEnum.AREA_UTILIZATION]: 'area-utilization',
      [routesEnum.AREA_UTILIZATION_VENUE_RENTAL]: 'venue-rental',
    },
  },
};
