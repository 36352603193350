import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ROUTES_FULL_PATHS } from '@core/routes/app.route-config.token';
import { TranslocoPipe } from '@jsverse/transloco';
import { IconComponent } from '@shared/components';
import { BtnDirective } from '@shared/directives';
import { LocalizePipe } from '@trendency/ng-translate';

@Component({
  selector: 'vk-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterLink, TranslocoPipe, LocalizePipe, BtnDirective, IconComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderSearchComponent {
  readonly routesFullPats = inject(ROUTES_FULL_PATHS);
}
