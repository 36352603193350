<button class="header-menu-button hamburger-menu-button" #megamenuButton (click)="onMegamenuClick()" vkBtn="tertiary">
  @if (breakpointState.isDesktop()) {
    <span class="menu-title-wrapper" [ngStyle]="{ 'min-width': isMegamenuActive() ? '70px' : '35px' }">
      <span class="menu-title menu-closed-title" [ngClass]="{ hidden: isMegamenuActive() }">{{ 'HEADER.LINKS.MENU' | transloco }}</span>
      <span class="menu-title menu-opened-title" [ngClass]="{ hidden: !isMegamenuActive() }">{{
        'HEADER.LINKS.MENU_CLOSE' | transloco
      }}</span>
    </span>
  }
  <vk-hamburger-menu [isOpen]="isMegamenuActive()" />
</button>

<div class="header-megamenu-backdrop" [ngClass]="{ isVisible: isMegamenuActive() }"></div>

<div class="header-megamenu-container" #megamenu [ngClass]="{ isOpen: isMegamenuActive() }">
  <div class="header-megamenu-items-container">
    @if (!breakpointState.isDesktop()) {
      <a class="header-megamenu-search-container" [routerLink]="routesFullPaths.SEARCH | localize" (click)="onMegamenuClick()">
        <div class="header-megamenu-search-icon-wrapper">
          <vk-icon name="icon-search"></vk-icon>
          {{ 'HEADER.LINKS.SEARCH' | transloco }}
        </div>
        <vk-icon class="header-megamenu-search-link-icon" name="icon-arrow-right"></vk-icon>
      </a>
    }

    <div class="header-megamenu-links-container">
      @for (megamenu of megamenuData; track $index) {
        <nav class="header-megamenu-link-container">
          <p class="header-megammenu-links-title">{{ megamenu.title | transloco }}</p>
          <ul class="header-megammenu-links">
            @for (megamenuItem of megamenu.items; track $index) {
              <li class="header-megammenu-link">
                <a
                  class="header-megammenu-link-button"
                  [routerLink]="megamenuItem.slug | localize"
                  (click)="onMegamenuClick()"
                  vkBtn="tertiary"
                  >{{ megamenuItem.title | transloco }}</a
                >
              </li>
            }
          </ul>
        </nav>
      }
    </div>

    <div class="header-megamenu-navigation-cards-container">
      <vk-navigation-card
        class="medium-clip-path"
        [title]="'HEADER.LINKS.MEGAMENU.NAVIGATION_CARD_TITLE' | transloco"
        [description]="breakpointState.isMobile() ? '' : ('HEADER.LINKS.MEGAMENU.NAVIGATION_CARD_DESCRIPTION' | transloco)"
        [url]="'URL'"
        (click)="onMegamenuClick()"
      />
    </div>
  </div>

  @if (breakpointState.isDesktop()) {
    <img class="header-megamenu-image" src="/assets/images/home-page-mega-menu.png" alt="{{ 'HEADER.LINKS.MEGAMENU.IMAGE' | transloco }}" />
  }
</div>
