import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ROUTES_FULL_PATHS } from '@core/routes/app.route-config.token';
import { TranslocoPipe } from '@jsverse/transloco';
import { IconComponent } from '@shared/components';
import { CONTENT_GRID, FOOTER_MENU_DATA } from '@shared/constants';
import { BtnDirective } from '@shared/directives';
import { LocalizePipe } from '@trendency/ng-translate';

@Component({
  selector: 'vk-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslocoPipe, LocalizePipe, BtnDirective, RouterLink, IconComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  readonly routesFullPaths = inject(ROUTES_FULL_PATHS);
  readonly footerMenuData = FOOTER_MENU_DATA;

  @HostBinding(CONTENT_GRID) contentGrid = true;
}
