import { CommonModule, DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, ElementRef, HostListener, inject, signal, viewChild } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ROUTES_FULL_PATHS } from '@core/routes/app.route-config.token';
import { BreakpointState } from '@core/states/breakpoint/breakpoint.state';
import { TranslocoPipe } from '@jsverse/transloco';
import { HamburgerMenuComponent, IconComponent, NavigationCardComponent } from '@shared/components';
import { MEGAMENU_DATA } from '@shared/constants';
import { BtnDirective, ZoomPanClampImagePreviewDirective } from '@shared/directives';
import { LocalizePipe } from '@trendency/ng-translate';

@Component({
  selector: 'vk-header-megamenu',
  templateUrl: './header-megamenu.component.html',
  styleUrls: ['./header-megamenu.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    TranslocoPipe,
    LocalizePipe,
    BtnDirective,
    IconComponent,
    NavigationCardComponent,
    HamburgerMenuComponent,
    ZoomPanClampImagePreviewDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMegamenuComponent {
  megamenu = viewChild<ElementRef<HTMLDivElement>>('megamenu');
  megamenuButton = viewChild<ElementRef<HTMLButtonElement>>('megamenuButton');

  readonly #document = inject(DOCUMENT);
  readonly breakpointState = inject(BreakpointState);
  readonly routesFullPaths = inject(ROUTES_FULL_PATHS);
  readonly megamenuData = MEGAMENU_DATA;

  readonly isMegamenuActive = signal(false);
  readonly scrollbarWidth = signal(this.#getScrollbarWidth());

  constructor() {
    if (!this.#document) return;

    effect(() => {
      if (this.#hasVerticalScrollbar()) {
        console.log('Header Megamenu Scrollbar width:', this.scrollbarWidth());
        this.#document.body.style.overflowY = this.isMegamenuActive() ? 'hidden' : 'auto';
        if (this.scrollbarWidth() > 0) {
          this.#document.body.style.paddingRight = this.isMegamenuActive() ? `${this.scrollbarWidth()}px` : '0';
        }
      }
    });
  }

  @HostListener('window:click', ['$event'])
  onGlobalClick(event: Event): void {
    event.stopImmediatePropagation();
    if (!this.megamenu() || !this.megamenu()?.nativeElement.contains(event?.target as Node)) {
      if (this.megamenu() && !this.megamenuButton()?.nativeElement.contains(event?.target as Node)) {
        this.isMegamenuActive.set(false);
      }
    }
  }

  onMegamenuClick(): void {
    this.isMegamenuActive.set(!this.isMegamenuActive());
  }

  #hasVerticalScrollbar(): boolean {
    return this.#document.documentElement.scrollHeight > this.#document.documentElement.clientHeight;
  }

  #getScrollbarWidth(): number {
    const outer = this.#document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll';
    this.#document.body.appendChild(outer);

    const inner = this.#document.createElement('div');
    outer.appendChild(inner);

    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    outer.parentNode?.removeChild(outer);

    return scrollbarWidth;
  }
}
