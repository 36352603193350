import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { InitService } from '@core/services/init/init.service';
import { UserService } from '@core/services/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  readonly #initService = inject(InitService);
  readonly #userService = inject(UserService);
  readonly #init = toSignal(this.#initService.init$());
  readonly #initUserIsLoggedIn = toSignal(this.#userService.getIsLoggedIn$());

  init(): void {
    this.#init();
    this.#initUserIsLoggedIn();
  }
}
