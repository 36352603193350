import { CommonModule, DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, effect, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { GlobalState } from '@core/states/global/global.state';
import { StripesState } from '@core/states/stripes/stripes.state';
import { LoaderComponent, ToasterContainerComponent, ToasterService } from '@shared/components';

import { BackgroundStripesComponent } from '../background-stripes/background-stripes.component';
import { FooterComponent } from '../footer/footer.component';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'vk-layout',
  styleUrls: ['./layout.component.scss'],
  templateUrl: './layout.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    HeaderComponent,
    FooterComponent,
    BackgroundStripesComponent,
    ToasterContainerComponent,
    LoaderComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent implements OnInit {
  readonly stripesState = inject(StripesState);
  readonly globalState = inject(GlobalState);
  readonly #router = inject(Router);
  readonly #destroyRef = inject(DestroyRef);
  readonly #toasterService = inject(ToasterService);
  readonly #document = inject(DOCUMENT);

  readonly toasterData = this.#toasterService.data;
  readonly scrollbarWidth = this.#getScrollbarWidth();

  constructor() {
    effect(() => {
      if (this.#hasVerticalScrollbar()) {
        if (this.globalState.routeChanged()) {
          console.log('routeChanged hide body scroll');
          this.#document.body.style.overflowY = 'hidden';

          if (this.scrollbarWidth > 0) {
            this.#document.body.style.paddingRight = `${this.scrollbarWidth}px`;
          }
        } else {
          setTimeout(() => {
            console.log('routeChanged show body scroll');
            this.#document.body.style.overflowY = 'auto';
            this.#document.body.style.paddingRight = '0';
          }, 1500);
        }
      }
    });
  }

  navigationInterceptor(event: Event): void {
    if (event instanceof NavigationStart) {
      console.log('NavigationStart');
      this.globalState.routeChanged = true;
      this.globalState.routeChangeStarted = true;
      this.globalState.routeChangeFinished = false;
    }
    if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
      console.log('NavigationEnd');
      this.globalState.routeChangeFinished = true;

      setTimeout(() => {
        this.globalState.routeChanged = false;
        this.globalState.routeChangeStarted = false;
        this.globalState.routeChangeFinished = false;
      }, 2000);
    }
  }

  #hasVerticalScrollbar(): boolean {
    return this.#document.documentElement.scrollHeight > this.#document.documentElement.clientHeight;
  }

  #getScrollbarWidth(): number {
    const outer = this.#document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll';
    this.#document.body.appendChild(outer);

    const inner = this.#document.createElement('div');
    outer.appendChild(inner);

    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    outer.parentNode?.removeChild(outer);

    return scrollbarWidth;
  }

  ngOnInit(): void {
    this.#router.events.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(this.navigationInterceptor.bind(this));
  }
}
