import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { LocalizeState } from '@core/states/localize/localize.state';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { BtnDirective } from '@shared/directives';
import { AvailableLangs } from 'src/environments';

@Component({
  selector: 'vk-header-lang-selector',
  templateUrl: './header-lang-selector.component.html',
  styleUrls: ['./header-lang-selector.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslocoPipe, BtnDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderLangSelectorComponent {
  readonly #localizeState = inject(LocalizeState);
  readonly #translocoService = inject(TranslocoService);

  onChangeLanguageClick(): void {
    const otherLang: string = this.#translocoService
      .getAvailableLangs()
      .filter((l) => l !== this.#translocoService.getActiveLang())[0] as string;
    this.#localizeState.changeLanguage(otherLang as AvailableLangs);
  }
}
