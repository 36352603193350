import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, computed, HostBinding, inject } from '@angular/core';
import { GlobalState } from '@core/states/global/global.state';
import { HomeState } from '@features/home/states/home.state';
import { CONTENT_GRID } from '@shared/constants';

@Component({
  selector: 'vk-background-stripes',
  standalone: true,
  imports: [],
  templateUrl: './background-stripes.component.html',
  styleUrl: './background-stripes.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('rightSlideIn', [
      state(
        'hidden',
        style({
          transform: 'translateX(100%)',
          opacity: 0,
        })
      ),
      state(
        'visible',
        style({
          transform: 'translateX(0)',
          opacity: 1,
        })
      ),
      transition('hidden => visible', [animate('1000ms ease-in')]),
    ]),
    trigger('leftSlideIn', [
      state(
        'hidden',
        style({
          transform: 'translateX(-100%)',
          opacity: 0,
        })
      ),
      state(
        'visible',
        style({
          transform: 'translateX(0)',
          opacity: 1,
        })
      ),
      transition('hidden => visible', [animate('1000ms ease-in')]),
    ]),
  ],
})
export class BackgroundStripesComponent {
  readonly #homeState = inject(HomeState);
  readonly #globalState = inject(GlobalState);

  readonly slideInState = computed(() => (this.#homeState.slideIn().homeSlideInHidden ? 'hidden' : 'visible'));

  @HostBinding(CONTENT_GRID) contentGrid = true;

  @HostBinding('style.--background-stripes-container-height.px') get stripesContainerHeight(): number {
    return this.#globalState.brandsWidgetBottom();
  }
}
