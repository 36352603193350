import { CdkMenuModule, CdkMenuTrigger } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, ElementRef, HostListener, inject, signal, viewChild } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AuthState } from '@auth/states/auth.state';
import { ROUTES_FULL_PATHS } from '@core/routes/app.route-config.token';
import { BreakpointState } from '@core/states/breakpoint/breakpoint.state';
import { TranslocoPipe } from '@jsverse/transloco';
import { IconComponent } from '@shared/components';
import { PROFILE_MENU_DATA } from '@shared/constants';
import { BtnDirective } from '@shared/directives';
import { UtilService } from '@shared/services';
import { LocalizePipe } from '@trendency/ng-translate';

@Component({
  selector: 'vk-header-profile-menu',
  templateUrl: './header-profile-menu.component.html',
  styleUrls: ['./header-profile-menu.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslocoPipe, LocalizePipe, CdkMenuModule, BtnDirective, IconComponent, RouterLink],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderProfileMenuComponent {
  profileMenuTrigger = viewChild(CdkMenuTrigger);
  profileMenu = viewChild<ElementRef<HTMLDivElement>>('profileMenu');
  profileMenuButton = viewChild<ElementRef<HTMLButtonElement>>('profileMenuButton');

  readonly #utilService: UtilService = inject(UtilService);
  readonly breakpointState = inject(BreakpointState);
  readonly authState = inject(AuthState);
  readonly routesFullPaths = inject(ROUTES_FULL_PATHS);
  readonly profileMenuData = PROFILE_MENU_DATA;

  readonly isProfileMenuActive = signal(false);
  readonly isSmallProfileMenuActive = signal(false);

  constructor() {
    if (!this.#utilService.isBrowser()) return;

    effect(() => {
      this.isProfileMenuActive() ? this.profileMenuTrigger()?.open() : this.profileMenuTrigger()?.close();
    });
  }

  @HostListener('window:click', ['$event'])
  onGlobalClick(event: Event): void {
    event.stopImmediatePropagation();
    if (!this.profileMenu() || !this.profileMenu()?.nativeElement.contains(event?.target as Node)) {
      if (this.profileMenu() && !this.profileMenuButton()?.nativeElement.contains(event?.target as Node)) {
        this.isProfileMenuActive.set(false);
        this.isSmallProfileMenuActive.set(false);
      }
    }
  }

  onProfileMenuClick(): void {
    this.isProfileMenuActive.set(this.profileMenuTrigger()?.isOpen() || false);
  }

  onSmallProfileMenuClick(): void {
    this.isSmallProfileMenuActive.set(!this.isSmallProfileMenuActive());
  }

  onLogout(): void {
    this.isSmallProfileMenuActive.set(false);
    this.authState.logout();
  }
}
