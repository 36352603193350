import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject, makeStateKey, PLATFORM_ID, TransferState } from '@angular/core';
import { Router } from '@angular/router';
import { ToasterService } from '@app/shared/components/toaster/services/toaster.service';
import { ROUTES_FULL_PATHS } from '@core/routes/app.route-config.token';
import { GlobalState } from '@core/states/global/global.state';
import { LocalizeState } from '@core/states/localize/localize.state';
import { LocalizeService } from '@trendency/ng-translate';
import { catchError, Observable, of, tap, throwError, timeout } from 'rxjs';
import { ENVIRONMENT, Environment } from 'src/environments';

let environment: Environment;
let isBrowser: boolean;
let localizeState: LocalizeState;
let toasterService: ToasterService;
let globalState: GlobalState;

export const apiInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
  environment = inject(ENVIRONMENT);
  isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  localizeState = inject(LocalizeState);
  toasterService = inject(ToasterService);
  globalState = inject(GlobalState);
  const localizeService = inject(LocalizeService);
  const routesFullPaths = inject(ROUTES_FULL_PATHS);
  const router = inject(Router);
  const transferState = inject(TransferState);

  const { url } = { ...request };
  const stateKey = makeStateKey(`http-get:${url}`);

  if (isBrowser) {
    globalState.incermentApiRequestCount();
    console.log(`${logDate()} API REQUEST (on CLIENT, Request count: ${globalState.apiRequestCount()}): `, url);
  }

  /**
   * If the application is running on the browser and the request method is GET and the response
   * is in the transfer state, return the response from the transfer state and remove it from the
   * transfer state. This way we do not need to repeat the request on the client.
   */
  if (isBrowser && request.method === 'GET' && transferState.hasKey(stateKey)) {
    const response = transferState.get<unknown>(stateKey, null);
    transferState.remove(stateKey);
    setTimeout(() => {
      globalState.decrementApiRequestCount();
      console.log(`${logDate()} API State RESPONSE (on CLIENT, Request count: ${globalState.apiRequestCount()}): `, url);
    }, 1500);
    return of(new HttpResponse({ body: response, status: 200 }));
  }

  request = request.clone({
    url: resolveUrl(request.url),
    withCredentials: true,
  });

  return next(request).pipe(
    timeout(environment.httpReqTimeout * 1000),
    tap((response) => {
      if (!isBrowser && request.method === 'GET' && response instanceof HttpResponse && response.status === 200) {
        transferState.set(stateKey, response.body);
      }

      if (isBrowser) {
        setTimeout(() => {
          globalState.decrementApiRequestCount();
          console.log(`${logDate()} API Pipe RESPONSE (on CLIENT, Request count: ${globalState.apiRequestCount()}): `, url);
        }, 1500);
      }
    }),
    catchError((err: HttpErrorResponse) => {
      if (isBrowser) {
        setTimeout(() => {
          globalState.decrementApiRequestCount();
          console.log(
            `${logDate()} API ERROR (on ${isBrowser ? 'CLIENT' : 'SERVER'}, Request count: ${globalState.apiRequestCount()}): `,
            url
          );
        }, 1500);
      }
      switch (err.status) {
        case 404:
          router.navigate([localizeService.translateRouteToCurrentLanguage(routesFullPaths.NOT_FOUND)], { skipLocationChange: true });
          break;
        default:
          return handleError(err);
      }
      return throwError(() => err);
    })
  );
};

const logDate = (): string => {
  return new Date().toLocaleDateString('hu', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
};

const handleError = (err: HttpErrorResponse, openToaster = true): Observable<never> => {
  if (openToaster && err.status !== 400) {
    toasterService.openToaster({ title: err.error.title, description: err.error.detail });
  }

  return throwError(() => err);
};

const resolveUrl = (url: string): string => {
  const absoluteUrlPattern = /^https?:\/\//i;

  if (absoluteUrlPattern.test(url)) {
    return url;
  }

  /**
   * If the application is running on the server, replace the https protocol with http
   * because the server does not support https.
   */
  const apiUrl = environment.apiUrl.replace('https://', isBrowser ? 'https://' : 'http://');

  if (url.indexOf('/') === 0) {
    const subUrl = url.slice(1, url.length);
    return `${apiUrl}/${localizeState.lang()}/${subUrl}`;
  }
  return `${apiUrl}/${localizeState.lang()}/${url}`;
};
