@if (!breakpointState.isMobile()) {
  <button
    class="header-plan-visit-menu-button"
    [vkBtn]="breakpointState.isDesktop() ? 'primary' : 'tertiary'"
    [ngClass]="{ 'small-screen': breakpointState.isTablet() }"
  >
    {{ 'HEADER.LINKS.VISIT_MAP' | transloco }} <vk-icon name="icon-signpost-big"></vk-icon>
  </button>
} @else {
  <button class="header-plan-visit-menu-button small-screen"><vk-icon name="icon-signpost-big"></vk-icon></button>
}
