<footer class="footer breakout">
  <div class="footer-left">
    <a class="footer-logo" [routerLink]="routesFullPaths.HOME | localize">
      <img class="footer-logo-img" src="/assets/images/icons/logo-footer.svg" alt="{{ 'HEADER.LOGO' | transloco }}" />
    </a>
    <div class="footer-social">
      <ul class="footer-social-links">
        <li>
          <a class="footer-social-link" href="">
            <vk-icon [name]="'icon-fb'"></vk-icon>
          </a>
        </li>
        <li>
          <a class="footer-social-link" href="">
            <vk-icon [name]="'icon-ig'"></vk-icon>
          </a>
        </li>
        <li>
          <a class="footer-social-link" href="">
            <vk-icon [name]="'icon-yt'"></vk-icon>
          </a>
        </li>
        <li>
          <a class="footer-social-link" href="">
            <vk-icon [name]="'icon-tiktok'"></vk-icon>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="footer-menu">
    @for (footerMenu of footerMenuData; track $index) {
      <div class="footer-menu-category">
        <p class="footer-menu-category-title">{{ footerMenu.title | transloco }}</p>
        <ul class="footer-menu-links">
          @for (footerMenuItem of footerMenu.items; track $index) {
            <li class="footer-menu-link-conatiner">
              <a class="footer-menu-link" [routerLink]="footerMenuItem.slug | localize">{{ footerMenuItem.title | transloco }}</a>
            </li>
          }
        </ul>
      </div>
    }
  </div>
  <div class="footer-separator"></div>
  <ul class="footer-legal">
    <li class="footer-legal-link-container">
      <a class="footer-legal-link" href="">{{ 'FOOTER.LEGAL.TERMS' | transloco }}</a>
    </li>
    <li class="footer-legal-link-container">
      <a class="footer-legal-link" href="">{{ 'FOOTER.LEGAL.PRIVACY_POLICY' | transloco }}</a>
    </li>
    <li class="footer-legal-link-container">
      <a class="footer-legal-link" href="">{{ 'FOOTER.LEGAL.COOKIE' | transloco }}</a>
    </li>
    <li class="footer-legal-link-container">
      <a class="footer-legal-link" href="">{{ 'FOOTER.LEGAL.IMPRESSUM' | transloco }}</a>
    </li>
    <p class="footer-legal-copyright">© 2024 Budavári Palotanegyed</p>
  </ul>
</footer>
