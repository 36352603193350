import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthService } from '@auth/services/auth.service';
import { AuthState } from '@auth/states/auth.state';
import { TranslocoService } from '@jsverse/transloco';
import { tapResponse } from '@ngrx/operators';
import { ToasterService } from '@shared/components';
import { map, Observable, of, switchMap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  readonly #authState = inject(AuthState);
  readonly #toasterService = inject(ToasterService);
  readonly #authService = inject(AuthService);
  readonly #translocoService = inject(TranslocoService);

  getIsLoggedIn$(): Observable<boolean> {
    // console.log('getIsLoggedIn$');
    return this.#authService.getIsLoggedIn$().pipe(
      tapResponse({
        next: ({ isLoggedIn }) => {
          // console.log('tapResponse next isLoggedIn: ', isLoggedIn);
          this.#authState.setIsLoggedIn(isLoggedIn);
        },
        error: (err: HttpErrorResponse) => {
          this.#toasterService.openToaster({ title: err.error.title, description: err.error.detail });
          return throwError(() => err);
        },
      }),
      switchMap(({ isLoggedIn }) => {
        // console.log('switchMap isLoggedIn: ', isLoggedIn);
        // console.log('switchMap this.#authState.isLoggedIn(): ', this.#authState.isLoggedIn());
        if (isLoggedIn) {
          this.loadUserInfo$();
        }

        return of(isLoggedIn);
      }),
      map((isLoggedIn) => isLoggedIn)
    );
  }

  loadUserInfo$(): void {
    this.#authService.getUserInfo$().pipe(
      tapResponse({
        next: ({ firstName, lastName, tags, email }) => this.#authState.setUser({ firstName, lastName, tags, email }),
        error: (err: HttpErrorResponse) => {
          this.#toasterService.openToaster({
            title: this.#translocoService.translate('ERROR.FETCH-USER-TITLE'),
            type: 'danger',
          });

          return throwError(() => err);
        },
      })
    );
  }
}
