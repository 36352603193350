import { effect, inject, Injectable } from '@angular/core';
import { MAT_DATE_LOCALE, MatDateFormats, NativeDateAdapter } from '@angular/material/core';
import { LocalizeState } from '@core/states/localize/localize.state';

@Injectable({
  providedIn: 'root',
})
export class CustomDateAdapter extends NativeDateAdapter {
  readonly #localizeState = inject(LocalizeState);
  #matLocale = inject(MAT_DATE_LOCALE);

  constructor() {
    super(inject(LocalizeState).lang());

    effect(() => {
      this.#matLocale = this.#localizeState.lang();
      this.setLocale(this.#matLocale);
    });
  }

  override format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      return date.toLocaleDateString(this.#localizeState.lang(), {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    }

    return date.toLocaleDateString(this.#localizeState.lang(), {
      year: 'numeric',
      month: 'long',
    });
  }

  override getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    switch (this.#localizeState.lang()) {
      case 'en':
        return this.#getEnDayOfWeekNames(style);
      case 'hu':
        return this.#getHuDayOfWeekNames(style);
      default:
        return this.#getHuDayOfWeekNames(style);
    }
  }

  #getEnDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    switch (style) {
      case 'long':
        return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      case 'short':
        return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      case 'narrow':
        return ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
      default:
        return [];
    }
  }

  #getHuDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    switch (style) {
      case 'long':
        return ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'];
      case 'short':
        return ['Va', 'He', 'Ke', 'Sze', 'Csü', 'Pé', 'Szo'];
      case 'narrow':
        return ['V', 'H', 'K', 'Sze', 'Cs', 'P', 'Szo'];
      default:
        return [];
    }
  }

  override getFirstDayOfWeek(): number {
    return this.#localizeState.lang() === 'hu' ? 1 : 0;
  }
}

export const CUSTOM_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'numeric', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};
