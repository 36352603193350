import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ROUTES_FULL_PATHS } from '@core/routes/app.route-config.token';
import { TranslocoPipe } from '@jsverse/transloco';
import { BtnDirective } from '@shared/directives';
import { LocalizePipe } from '@trendency/ng-translate';

@Component({
  selector: 'vk-header-logo',
  templateUrl: './header-logo.component.html',
  styleUrls: ['./header-logo.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterLink, TranslocoPipe, LocalizePipe, BtnDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderLogoComponent {
  readonly routesFullPaths = inject(ROUTES_FULL_PATHS);
}
