import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import localeHuExtra from '@angular/common/locales/extra/hu';
import localeHu from '@angular/common/locales/hu';
import { APP_INITIALIZER, ApplicationConfig, LOCALE_ID, Provider } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  InMemoryScrollingFeature,
  InMemoryScrollingOptions,
  withComponentInputBinding,
  withInMemoryScrolling,
  withViewTransitions,
} from '@angular/router';
import { apiInterceptor } from '@core/interceptors/api.interceptor';
import { ROUTES_FULL_PATHS } from '@core/routes/app.route-config.token';
import { routesFullPaths } from '@core/routes/app.routes-config';
import { localizeRouteConfig } from '@core/routes/app.routes-localize.config';
import { AppInitializerService } from '@core/services/app-initializer/app-initializer.service';
import { CUSTOM_DATE_FORMATS, CustomDateAdapter } from '@shared/components/forms/components/datepicker/adapters/custom.date.adapter';
import { provideLocalizedRouter } from '@trendency/ng-translate';
import { provideSimpleConfig } from '@trendency/simple';
import { simpleConfig } from '@widgets/configs';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { ENVIRONMENT, environment } from 'src/environments';

registerLocaleData(localeHu, 'hu-HU', localeHuExtra);

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};

const inMemoryScrollingFeature: InMemoryScrollingFeature = withInMemoryScrolling(scrollConfig);

const provideDateLocaleConfig = (): Provider[] => {
  return [
    {
      provide: LOCALE_ID,
      useValue: 'hu-HU',
    },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: CUSTOM_DATE_FORMATS,
    },
  ];
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideLocalizedRouter(localizeRouteConfig, withComponentInputBinding(), withViewTransitions(), inMemoryScrollingFeature),
    provideHttpClient(withFetch(), withInterceptors([apiInterceptor])),
    provideClientHydration(),
    provideAnimationsAsync(),
    provideSimpleConfig(simpleConfig),
    provideDateLocaleConfig(),
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },
    {
      provide: ROUTES_FULL_PATHS,
      useValue: routesFullPaths,
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.siteKeyV2,
      } as RecaptchaSettings,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitializerService: AppInitializerService) => () => appInitializerService.init(),
      deps: [AppInitializerService],
      multi: true,
    },
  ],
};
