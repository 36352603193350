<div class="loader" [ngClass]="{ hidden: !globalState.routeChanged() }">
  <div class="backdrop"></div>
  <vk-loader />
</div>

<vk-header />
<main class="stackable-grid" id="main-content" tabindex="-1">
  @if (stripesState.isStripesVisible()) {
    <vk-background-stripes class="full-width" />
  }
  <router-outlet />
</main>
<vk-footer />

@if (toasterData().length > 0) {
  <vk-toaster-container [data]="toasterData()" />
}
