import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, ElementRef, inject, isDevMode, OnInit } from '@angular/core';
import { LayoutComponent } from '@core/components/layout/layout.component';
import { LocalizeState } from '@core/states/localize/localize.state';

@Component({
  selector: 'vk-root',
  standalone: true,
  imports: [LayoutComponent],
  template: `<vk-layout></vk-layout>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  readonly #document = inject(DOCUMENT);
  readonly #elementRef = inject(ElementRef);
  readonly #localizeState = inject(LocalizeState);

  constructor() {
    effect(() => {
      if (this.#document) {
        this.#document.documentElement.lang = this.#localizeState.lang();
      }
    });
  }

  ngOnInit(): void {
    if (!isDevMode()) {
      this.#elementRef.nativeElement.removeAttribute('ng-version');
    }
  }
}
